<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info-box">
            <p>{{ $t('deposit.skrill.header') }}</p>
            <DepositInfo :instData="instData" :noteData="noteData"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">{{ $t('deposit.skrill.form.header') }}</p>
            <el-form label-position="top" :model="skrillForm" ref="skrillForm" :rules="skrillRules" status-icon>
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="validCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, skrillForm.amount)" prop="amount">
                      <numeric-input
                        v-model="skrillForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item :label="$t('deposit.skrill.form.email')" prop="email">
                      <el-input v-model="skrillForm.email" data-testid="email"></el-input>
                    </el-form-item>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="skrillForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <div class="info">
                <ul v-html="$t('deposit.skrill.reminder', { platform: $config.info.fullName })"></ul>
              </div>
              <el-form-item>
                <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="logo rectangle skrill"></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import { apiSkrill_payment } from '@/resource';
import mixin from '@/mixins/page/deposit';

export default {
  name: 'skrill',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.maxLimitByUSC) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };
    return {
      skrillForm: {
        accountNumber: '',
        amount: '',
        email: '',
        notes: ''
      },
      skrillRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      validCurrencies: ['USD', 'GBP', 'CAD', 'EUR', 'USC'],
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst2', { method: this.$t('common.withdrawChannel.skrill') }),
        this.$t('deposit.reminder.inst3', { method: this.$t('common.withdrawChannel.skrill') })
      ],
      noteData: [
        this.$t('deposit.skrill.note1'),
        this.$t('deposit.skrill.note2'),
        this.$t('deposit.skrill.note3'),
        this.$t('deposit.skrill.note4')
      ]
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.skrillForm.accountNumber = accountNumber;
    },
    submitForm(e) {
      this.$refs['skrillForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data && result.data.code == 0) {
                const msg = result.data.msg;
                if (msg) window.location = `https://pay.skrill.com/?sid=${msg}`;
                else {
                  this.loading = false;
                  this.errorMessage(this.$t('deposit.default.failed'));
                }
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiSkrill_payment(
        {
          mt4Account: this.skrillForm.accountNumber,
          operateAmount: this.skrillForm.amount,
          applicationNotes: this.skrillForm.notes,
          skrillEmail: this.skrillForm.email,
          paymentChannel: '1'
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
